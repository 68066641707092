import { CSVUploadResult } from "common/components/CsvUpload";
import { SwipeRxPt, SwipeRxPtV3Resources } from "../..";

export class SwipeRxPtLogisticsOrderUpdate {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.LOGISTICS_ORDER_UPDATE;
  }

  async uploadCsv(file: File): Promise<{ data: CSVUploadResult }> {
    const form = new FormData();
    form.append('file', new Blob([await file.arrayBuffer()], { type: 'text/csv' }), file.name);
    return this.base.post(`${this.resource}/upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/upload/history`, {
      page,
      page_size,
    });
  }
}
