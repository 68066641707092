import React from 'react';
import { oc } from 'ts-optchain';

import Grid from '@material-ui/core/Grid';

import { ColorType, DataObject } from 'types';
import { Badge, ConditionalRender } from 'common/components';

import { OrderPrecursorStatus } from 'pages/transactions/types';
import { CardTableRowFooterPayload } from '../../interfaces';
import { CardTableRowFooter as FooterType } from '../../types';

import { RowFooterButton } from '../RowFooterButton';

import { S } from './CardTableRowFooter.styles';

interface Props {
  colSpan: number;
  data: DataObject;
  footer: FooterType;
}

export const CardTableRowFooter: React.FC<Props> = ({ colSpan, data, footer }) => {
  const customFooter = React.isValidElement(footer(data)) ? footer(data) : null;
  const footerPayload = footer(data) as CardTableRowFooterPayload;

  const renderLabels = (): JSX.Element[] =>
    oc(footerPayload)
      .labels([])
      .map(({ id, type, node, bg }) => (
        <Badge light key={id} color={type} bg={bg}>
          {node}
        </Badge>
      ));

  const renderActions = (): JSX.Element[] =>
    oc(footerPayload)
      .actions([])
      .map((action) => {

        return (
          <RowFooterButton
            data-testid={`button-order-${data.po_number}`}
            disabled={action.disabled}
            key={action.name}
            data={data}
            onClick={footerPayload?.onActionClick}
            {...action}
          />
        );
      });

  return (
    <S.Wrapper>
      <td colSpan={colSpan}>
        <ConditionalRender condition={!!customFooter}>{customFooter}</ConditionalRender>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
          <Grid item xs={7}>
            <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
              <ConditionalRender condition={!!footerPayload.primaryLabel}>
                <Grid item>
                  <S.PrimaryLabel color={footerPayload?.primaryLabel?.type || ColorType.DANGER}>
                    {oc(footerPayload.primaryLabel).node()}
                  </S.PrimaryLabel>
                </Grid>
              </ConditionalRender>
              <ConditionalRender condition={!!footerPayload.labels}>
                <Grid item xs={8}>
                  {renderLabels()}
                </Grid>
              </ConditionalRender>
            </Grid>
          </Grid>
          <Grid item>
            <ConditionalRender condition={!!footerPayload.actions}>{renderActions()}</ConditionalRender>
          </Grid>
        </Grid>
      </td>
    </S.Wrapper>
  );
};
