import React from 'react';

import {
  DISTRIBUTOR_CALENDAR_MANAGER_ROLES,
  DISTRIBUTOR_MEMBER_ROLES,
  PRODUCT_MEMBER_ROLES,
  VOUCHER_VIEWER_ROLES,
  UserRoles,
  TRANSACTION_ACCESS_ROLES,
  LOYALTY_ROLES,
  PHARMACY_UPLOAD_ROLES,
  CRM_UPLOAD_ROLES,
} from 'common/constants';

import { IModuleConfig } from 'common/reducers';
import { RouteConfigOptions } from './routes.interface';
import { RouteType } from './routes.constants';

// TODO: attach it to the route config
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const CreditMemoUploadAttachmentPage = React.lazy(
  () => import('pages/finance/components/credit-memo-attachment-upload-page/credit-memo-attachment-upload.component'),
);

const CreditsPage = React.lazy(() => import('pages/credits/credits-page.component'));
const InitialPage = React.lazy(() => import('pages/initial-page/initial-page.component'));
const OrdersPage = React.lazy(() => import('common/containers/Orders/Orders.container'));
const InvoicesPage = React.lazy(() => import('common/containers/Invoices/Invoices.container'));
const PharmaciesPage = React.lazy(() => import('../administrator/containers/Pharmacies/Pharmacies.container'));
// const CreditsPage = React.lazy(() => import('../administrator/containers/Credit/credit.container'));
const CreditBulkUpgradePage = React.lazy(() => import('../pages/credits/components/credit-limit-bulk.component'));
const TransactionsPage = React.lazy(() => import('../pages/transactions/TransactionsPage.component'));
const BacklogPage = React.lazy(() => import('common/containers/Backlog/Backlog.component'));
const DocumentManagement = React.lazy(() => import('../pages/document-management/DocumentManagementPage.component'));
const DocumentPharmacy = React.lazy(
  () => import('../pages/document-management/pharmacy/DocumentManagementPharmacyPage.component'),
);

const FinancePage = React.lazy(() => import('../pages/finance/finance-page.component'));
const ProductPage = React.lazy(() => import('../pages/ProductPage/ProductPage.component'));
const ProductDetailPage = React.lazy(
  () => import('../pages/ProductPage/components/ProductDetails/ProductDetails.component'),
);
const ProductMarkdownPage = React.lazy(
  () => import('../pages/ProductPage/components/ProductMarkdownPage/ProductMarkdown.component'),
);
const ProductUploadPage = React.lazy(() => import('../pages/ProductPage/components/UploadPage/container.component'));
const PharmacyUploadPage = React.lazy(() => import('../administrator/containers/Pharmacies/tabs/tab-page.component'));
const PharmacyCrmUploadPage = React.lazy(
  () => import('../administrator/containers/Pharmacies/Crm/tabs/tab-page.component'),
);

const DistributorPage = React.lazy(() => import('../pages/distributor/distributor-page.component'));
const DistributorDetailPage = React.lazy(
  () => import('../pages/distributor/components/distributor-detail-wrapper/distributor-detail-wrapper.component'),
);
const MarketingPage = React.lazy(() => import('../pages/marketing/marketing-page.component'));
const MarketingRewardsPage = React.lazy(() => import('../pages/marketing-rewards/marketing-rewards-page.component'));
const RewardsListPage = React.lazy(
  () => import('../pages/marketing-rewards/components/marketing-rewards-list-page.component'),
);
const RewardsUpdatePage = React.lazy(
  () => import('../pages/marketing-rewards/components/marketing-rewards-edit-page.component'),
);
const PBFAssignmentPage = React.lazy(() => import('../pages/pbf-assignment/pbf-assignment-page.component'));
const PharmacyUsersPage = React.lazy(() => import('../pages/pharmacy-users/pharmacy-users-page.wrapper'));
const PharmacyUserDetailPage = React.lazy(() => import('../pages/pharmacy-users/components/user-detail'));
const LogisticPage = React.lazy(() => import('../pages/logistic/logistic-page.component'));
const BulkDispatchPage = React.lazy(() => import('../pages/BulkDispatch/bulk-dispatch-page.component'));
const LogisticsCalendarPage = React.lazy(() => import('../pages/LogisticsCalendar/logistics-calendar-page.component'));
const LogisticsAreaDetailPage = React.lazy(
  () => import('../pages/logistics-area-detail/logistics-area-detail-page.component'),
);
const VoucherPage = React.lazy(() => import('../pages/voucher/voucher-page.component'));
const VoucherDetailPage = React.lazy(() => import('../pages/voucher/voucher-detail-page.component'));
const VoucherInformationPage = React.lazy(() => import('../pages/voucher/tabs/voucher-information.component'));

const MarketingSegmentsPage = React.lazy(() => import('../pages/MarketingSegments/MarketingSegmentsPage.component'));
const LoyaltyPage = React.lazy(() => import('../pages/Loyalty/LoyaltyMissionPage.component'));

const PharmacyUserPTDetailPage = React.lazy(() => import('../pages/pharmacy-users/components/user-detail-pt'));

export const routeConfig: (prefix: string, modules: IModuleConfig) => RouteConfigOptions[] = (prefix, modules) => {
  return [
    {
      path: `${prefix}`,
      component: InitialPage,
      type: RouteType.PRIVATE,
      exact: true,
    },
    {
      path: `${prefix}/overdue/invoices`,
      component: BacklogPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.CUSTOMER_SUPPORT, UserRoles.FINANCE],
    },
    {
      path: `${prefix}/pending/deposits`,
      component: BacklogPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.CUSTOMER_SUPPORT, UserRoles.FINANCE],
    },
    {
      path: `${prefix}/unmatched/deposits`,
      component: BacklogPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.CUSTOMER_SUPPORT, UserRoles.FINANCE],
    },
    {
      path: `${prefix}/mismatched/allocations`,
      component: BacklogPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.CUSTOMER_SUPPORT, UserRoles.FINANCE],
    },
    {
      path: `${prefix}/orders`,
      component: OrdersPage,
      type: RouteType.PRIVATE,
    },
    {
      path: `${prefix}/invoices`,
      component: InvoicesPage,
      type: RouteType.PRIVATE,
    },
    {
      path: `${prefix}/pharmacies/:pharmacyId?/:selectedTab?`,
      component: PharmaciesPage,
      type: RouteType.PROTECTED,
      roleAccess: [
        UserRoles.CUSTOMER_SUPPORT,
        UserRoles.FINANCE,
        UserRoles.TRANSACTION_MANAGER,
        UserRoles.SALES_SUPPORT,
        UserRoles.PHARMACIST,
        UserRoles.MARKETING,
        UserRoles.SALES_SUPERVISOR,
      ],
      exact: true,
    },
    {
      path: `${prefix}/pharmacies-crm-upload`,
      component: PharmacyCrmUploadPage,
      type: RouteType.PROTECTED,
      roleAccess: CRM_UPLOAD_ROLES,
    },
    {
      path: `${prefix}/pharmacies-upload`,
      component: PharmacyUploadPage,
      type: RouteType.PROTECTED,
      roleAccess: PHARMACY_UPLOAD_ROLES,
    },
    {
      path: `${prefix}/credits/:selectedTab?`,
      component: CreditsPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.CUSTOMER_SUPPORT],
      exact: true,
    },
    {
      path: `${prefix}/bulk-credit-upgrade`,
      component: CreditBulkUpgradePage,
      type: RouteType.PRIVATE,
      exact: true,
    },
    {
      path: `${prefix}/transactions`,
      component: TransactionsPage,
      type: RouteType.PROTECTED,
      roleAccess: modules.transactions['view-module'] || TRANSACTION_ACCESS_ROLES,
    },
    {
      path: `${prefix}/document-management/finance/:status?`,
      component: DocumentManagement,
      type: RouteType.PROTECTED,
      roleAccess: [
        UserRoles.DISPATCHER,
        UserRoles.VENDOR_SPECIALIST,
        UserRoles.FULFILLMENT_ADMIN,
        UserRoles.TRANSACTION_MANAGER,
        UserRoles.SENIOR_VENDOR_SPECIALIST,
        UserRoles.FINANCE,
      ],
    },
    {
      path: `${prefix}/document-management/pharmacy/:status?`,
      component: DocumentPharmacy,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.SALES_SUPPORT, UserRoles.CUSTOMER_SUPPORT],
    },
    {
      path: `${prefix}/finance`,
      component: FinancePage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.FINANCE],
    },
    {
      path: `${prefix}/product`,
      component: ProductPage,
      type: RouteType.PROTECTED,
      exact: true,
      roleAccess: PRODUCT_MEMBER_ROLES,
    },
    {
      path: `${prefix}/product/:id`,
      component: ProductDetailPage,
      type: RouteType.PROTECTED,
      roleAccess: PRODUCT_MEMBER_ROLES,
    },
    {
      path: `${prefix}/product-upload`,
      component: ProductUploadPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.PHARMACEUTICAL_DATA_ANALYST],
    },
    {
      path: `${prefix}/product-markdown-playground`,
      component: ProductMarkdownPage,
      type: RouteType.PROTECTED,
      exact: true,
      roleAccess: PRODUCT_MEMBER_ROLES,
    },
    {
      path: `${prefix}/distributor`,
      component: DistributorPage,
      type: RouteType.PROTECTED,
      exact: true,
      roleAccess: DISTRIBUTOR_MEMBER_ROLES,
    },
    {
      path: `${prefix}/distributor/:id/:selectedTab?`,
      component: DistributorDetailPage,
      type: RouteType.PROTECTED,
      roleAccess: DISTRIBUTOR_MEMBER_ROLES,
    },
    {
      path: `${prefix}/marketing/:tabId/:marketing_id?`,
      component: MarketingPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.MARKETING],
    },
    {
      exact: true,
      path: `${prefix}/marketing-rewards`,
      component: MarketingRewardsPage,
      type: RouteType.PROTECTED,
      roleAccess: LOYALTY_ROLES,
    },
    {
      exact: true,
      path: `${prefix}/marketing-rewards/:id`,
      component: RewardsListPage,
      type: RouteType.PROTECTED,
      roleAccess: LOYALTY_ROLES,
    },
    {
      exact: true,
      path: `${prefix}/marketing-rewards/:id/:edit`,
      component: RewardsUpdatePage,
      type: RouteType.PROTECTED,
      roleAccess: LOYALTY_ROLES,
    },
    {
      path: `${prefix}/pbf-assignment`,
      component: PBFAssignmentPage,
      type: RouteType.PROTECTED,
      roleAccess: modules['pbf-assignment']['view-module'],
    },
    {
      path: `${prefix}/pharmacy-users`,
      component: PharmacyUsersPage,
      type: RouteType.PROTECTED,
      roleAccess: modules['pharmacy-users']['view-module'],
    },
    {
      path: `${prefix}/marketing-segments`,
      component: MarketingSegmentsPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.MARKETING],
    },
    {
      path: `${prefix}/loyalty`,
      component: LoyaltyPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.MARKETING, UserRoles.SALES_SUPPORT],
    },
    {
      exact: true,
      path: `${prefix}/logistics-area`,
      component: LogisticPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.TRANSACTION_MANAGER],
    },
    {
      exact: true,
      path: `${prefix}/logistics-area/:id`,
      component: LogisticsAreaDetailPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.TRANSACTION_MANAGER],
    },
    {
      exact: true,
      path: `${prefix}/bulk-dispatch`,
      component: BulkDispatchPage,
      type: RouteType.PROTECTED,
      roleAccess: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.TRANSACTION_MANAGER, UserRoles.DISPATCHER],
    },
    {
      exact: true,
      path: `${prefix}/distributor-calendar`,
      component: LogisticsCalendarPage,
      type: RouteType.PROTECTED,
      roleAccess: DISTRIBUTOR_CALENDAR_MANAGER_ROLES,
    },
    {
      exact: true,
      path: `${prefix}/new-voucher`,
      component: VoucherInformationPage,
      type: RouteType.PROTECTED,
      roleAccess: VOUCHER_VIEWER_ROLES,
    },
    {
      exact: true,
      path: `${prefix}/vouchers`,
      component: VoucherPage,
      type: RouteType.PROTECTED,
      roleAccess: modules.voucher['view-module'] as UserRoles[],
    },
    {
      exact: true,
      path: `${prefix}/voucher/:id/:action?`,
      component: VoucherDetailPage,
      type: RouteType.PROTECTED,
      roleAccess: VOUCHER_VIEWER_ROLES,
    },
  ];
};
