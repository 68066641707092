import { TransactionActionItemMapByStatus } from 'types';

import {
  UserRoles,
  TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
  // TRANSACTION_LOGISTIC_DELIVERY_ROLES,
  TRANSACTION_MEMBER_ROLES,
} from './roles.constants';
import { TRANSACTION_ACTIONS } from './transaction-actions.constant';

export const TRANSACTION_ACTION_ITEM_MAP_BY_STATUS: TransactionActionItemMapByStatus = {
  accepted: {
    single: [],
    multiple: [
      {
        actionItem: TRANSACTION_ACTIONS.SEND_TO_DISPATCHER,
        allowedRoles: [
          UserRoles.VENDOR_SPECIALIST,
          UserRoles.FULFILLMENT_ADMIN,
          UserRoles.SENIOR_VENDOR_SPECIALIST,
          UserRoles.TRANSACTION_MANAGER,
          UserRoles.DISTRIBUTOR_STAFF,
          UserRoles.DISPATCHER,
        ],
        subActions: [
          {
            actionItem: TRANSACTION_ACTIONS.DELAY,
          },
          {
            actionItem: TRANSACTION_ACTIONS.REPRINT,
            allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
          },
          {
            actionItem: TRANSACTION_ACTIONS.PRINT_DELIVERY_PROOF,
            allowedRoles: [...TRANSACTION_MEMBER_ROLES, UserRoles.DISPATCHER],
          },
          {
            actionItem: TRANSACTION_ACTIONS.PRINT_DELIVERY_DOCS,
            allowedRoles: [...TRANSACTION_MEMBER_ROLES, UserRoles.DISPATCHER],
          },
          {
            actionItem: TRANSACTION_ACTIONS.PRINT_INVOICE_DOCS,
          },
          {
            actionItem: TRANSACTION_ACTIONS.DELETE_SHIPMENT,
            allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
          },
          undefined,
          {
            actionItem: TRANSACTION_ACTIONS.ROLLBACK,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER, UserRoles.DISTRIBUTOR_STAFF],
          },
          {
            actionItem: TRANSACTION_ACTIONS.CANCEL,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER],
          },
        ],
      },
      {
        actionItem: TRANSACTION_ACTIONS.PRINT_DELIVERY_DOCS,
        subActions: [
          {
            actionItem: TRANSACTION_ACTIONS.EXPORT,
            allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
          },
          {
            actionItem: TRANSACTION_ACTIONS.REPRINT,
            allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
          },
        ],
      },
    ],
  },
  cancelled: {
    single: [],
    multiple: [
      {
        actionItem: TRANSACTION_ACTIONS.RESTORE,
        allowedRoles: [UserRoles.TRANSACTION_MANAGER],
      },
    ],
  },
  delivered: {
    single: [],
    multiple: [
      {
        actionItem: TRANSACTION_ACTIONS.FULFILL,
        allowedRoles: [
          UserRoles.TRANSACTION_MANAGER,
          UserRoles.VENDOR_SPECIALIST,
          UserRoles.FULFILLMENT_ADMIN,
          UserRoles.SENIOR_VENDOR_SPECIALIST,
          UserRoles.DISPATCHER,
        ],
        subActions: [
          {
            actionItem: TRANSACTION_ACTIONS.RETURN,
            allowedRoles: [
              UserRoles.TRANSACTION_MANAGER,
              UserRoles.VENDOR_SPECIALIST,
              UserRoles.FULFILLMENT_ADMIN,
              UserRoles.SENIOR_VENDOR_SPECIALIST,
              UserRoles.DISPATCHER,
            ],
          },
          undefined,
          {
            actionItem: TRANSACTION_ACTIONS.ROLLBACK,
            allowedRoles: [UserRoles.SUPER_ADMIN, UserRoles.PHARMACIST],
          },
        ],
      },
    ],
  },
  dispatched: {
    single: [],
    multiple: [
      {
        actionItem: TRANSACTION_ACTIONS.DELIVER,
        allowedRoles: [
          UserRoles.TRANSACTION_MANAGER,
          UserRoles.VENDOR_SPECIALIST,
          UserRoles.FULFILLMENT_ADMIN,
          UserRoles.SENIOR_VENDOR_SPECIALIST,
          UserRoles.DISPATCHER,
        ],
        subActions: [
          {
            actionItem: TRANSACTION_ACTIONS.MARK_AS_UNDELIVERED,
            allowedRoles: [
              UserRoles.TRANSACTION_MANAGER,
              UserRoles.VENDOR_SPECIALIST,
              UserRoles.FULFILLMENT_ADMIN,
              UserRoles.SENIOR_VENDOR_SPECIALIST,
              UserRoles.DISPATCHER,
            ],
          },
          {
            actionItem: TRANSACTION_ACTIONS.REPRINT_DELIVERY_PROOF,
            allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
          },
          {
            actionItem: TRANSACTION_ACTIONS.REPRINT_DELIVERY_DOCS,
            allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
          },
          {
            actionItem: TRANSACTION_ACTIONS.REPRINT_INVOICE_DOCS,
            allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
          },
          /* {
            actionItem: TRANSACTION_ACTIONS.UPDATE_TRACKING_NUMBER,
            allowedRoles: TRANSACTION_LOGISTIC_DELIVERY_ROLES,
          },
          {
            actionItem: TRANSACTION_ACTIONS.UPDATE_DELIVERY_STATUS,
            allowedRoles: TRANSACTION_LOGISTIC_DELIVERY_ROLES,
          }, */
          {
            actionItem: TRANSACTION_ACTIONS.RETURN,
            allowedRoles: [
              UserRoles.TRANSACTION_MANAGER,
              UserRoles.VENDOR_SPECIALIST,
              UserRoles.FULFILLMENT_ADMIN,
              UserRoles.SENIOR_VENDOR_SPECIALIST,
              UserRoles.DISPATCHER,
            ],
          },
          undefined,
          {
            actionItem: TRANSACTION_ACTIONS.ROLLBACK,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER, UserRoles.DISTRIBUTOR_STAFF],
          },
          {
            actionItem: TRANSACTION_ACTIONS.CANCEL,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER],
          },
        ],
      },
    ],
  },
  fulfilled: {
    single: [],
    multiple: [
      {
        actionItem: TRANSACTION_ACTIONS.UPDATE_DELIVERY_DOCS,
        allowedRoles: [
          UserRoles.TRANSACTION_MANAGER,
          UserRoles.VENDOR_SPECIALIST,
          UserRoles.FULFILLMENT_ADMIN,
          UserRoles.SENIOR_VENDOR_SPECIALIST,
        ],
        subActions: [
          undefined,
          {
            actionItem: TRANSACTION_ACTIONS.ROLLBACK,
            allowedRoles: [UserRoles.SUPER_ADMIN, UserRoles.PHARMACIST],
          },
        ],
      },
    ],
  },
  pending: {
    single: [],
    multiple: [
      {
        actionItem: TRANSACTION_ACTIONS.DISABLED_PRINT,
        allowedRoles: [
          UserRoles.VENDOR_SPECIALIST,
          UserRoles.FULFILLMENT_ADMIN,
          UserRoles.SENIOR_VENDOR_SPECIALIST,
          UserRoles.SUPER_ADMIN,
          UserRoles.PHARMACIST,
          UserRoles.ADMIN,
          UserRoles.DISTRIBUTOR_STAFF,
        ],
        subActions: [
          {
            actionItem: TRANSACTION_ACTIONS.DELAY,
          },
          {
            actionItem: TRANSACTION_ACTIONS.REASSIGN,
            allowedRoles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.PHARMACIST],
          },
          undefined,
          {
            actionItem: TRANSACTION_ACTIONS.CANCEL_ENABLED,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER],
          },
        ],
      },
      {
        actionItem: TRANSACTION_ACTIONS.PRINT,
        allowedRoles: [
          UserRoles.VENDOR_SPECIALIST,
          UserRoles.FULFILLMENT_ADMIN,
          UserRoles.SENIOR_VENDOR_SPECIALIST,
          UserRoles.SUPER_ADMIN,
          UserRoles.PHARMACIST,
          UserRoles.ADMIN,
          UserRoles.TRANSACTION_MANAGER,
          UserRoles.DISTRIBUTOR_STAFF,
        ],
        subActions: [
          {
            actionItem: TRANSACTION_ACTIONS.DELAY,
          },
          {
            actionItem: TRANSACTION_ACTIONS.REASSIGN,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER, UserRoles.ADMIN],
          },
          undefined,
          {
            actionItem: TRANSACTION_ACTIONS.CANCEL,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER, UserRoles.ADMIN],
          },
        ],
      },
    ],
  },
  processing: {
    single: [
      {
        actionItem: TRANSACTION_ACTIONS.REPRINT,
        allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
      },
    ],
    multiple: [
      {
        actionItem: TRANSACTION_ACTIONS.ACCEPT,
        allowedRoles: [
          UserRoles.VENDOR_SPECIALIST,
          UserRoles.FULFILLMENT_ADMIN,
          UserRoles.SENIOR_VENDOR_SPECIALIST,
          UserRoles.TRANSACTION_MANAGER,
          UserRoles.DISTRIBUTOR_STAFF,
        ],
        subActions: [
          {
            actionItem: TRANSACTION_ACTIONS.DELAY,
          },
          {
            actionItem: TRANSACTION_ACTIONS.REPRINT,
            allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
          },
          {
            actionItem: TRANSACTION_ACTIONS.REASSIGN,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER],
          },
          undefined,
          {
            actionItem: TRANSACTION_ACTIONS.CANCEL,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER],
          },
        ],
      },
      {
        actionItem: TRANSACTION_ACTIONS.DISABLED_ACCEPT,
        allowedRoles: [
          UserRoles.VENDOR_SPECIALIST,
          UserRoles.FULFILLMENT_ADMIN,
          UserRoles.SENIOR_VENDOR_SPECIALIST,
          UserRoles.DISTRIBUTOR_STAFF,
        ],
        subActions: [
          {
            actionItem: TRANSACTION_ACTIONS.DELAY,
          },
          {
            actionItem: TRANSACTION_ACTIONS.REPRINT,
            allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
          },
          {
            actionItem: TRANSACTION_ACTIONS.REASSIGN,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER],
          },
          undefined,
          {
            actionItem: TRANSACTION_ACTIONS.CANCEL,
            allowedRoles: [UserRoles.TRANSACTION_MANAGER],
          },
        ],
      },
    ],
  },
  delayed: {
    single: [],
    multiple: [
      {
        actionItem: TRANSACTION_ACTIONS.CANCEL,
        allowedRoles: [UserRoles.TRANSACTION_MANAGER],
      },
    ],
  },
};
