import { useDispatch, useGlobal } from 'reactn';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment-timezone';

import { ColorType, DataObject, TransactionActionName } from 'types';
import { getMarketPath, IMarketParams } from 'utils/get-router-path';
import { useSnackBar, useUpdateQueryParams } from 'hooks';
import { PaymentProvider, PAYMENT_METHOD_DIRECT_PAYMENT, ORDER_RETURN_TIME_LIMIT } from 'common/constants';
import { OrderStatus } from 'common/services/apis/v3';

type ProcessTransactionRowAction = (name: string, order: DataObject) => void;

export const useTransactionRowAction = (): ProcessTransactionRowAction => {
  const {
    params: { marketId },
  } = useRouteMatch<IMarketParams>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { queryParams, updateQueryParams } = useUpdateQueryParams();
  const acceptOrders = useDispatch('acceptOrders');
  const confirmAcceptOrders = useDispatch('confirmAcceptOrders');
  const confirmRestoreOrders = useDispatch('confirmRestoreOrders');
  const processOrders = useDispatch('processOrders');
  const initializeOrderDelay = useDispatch('initializeOrderDelay');
  const initializeOrderRollback = useDispatch('initializeOrderRollback');
  const sendToDispatcher = useDispatch('sendToDispatcher');
  const markOrdersAsUndelivered = useDispatch('markOrdersAsUndelivered');
  /* const initializeDeliveryTracking = useDispatch('initializeDeliveryTracking');
  const initializeDeliveryStatus = useDispatch('initializeDeliveryStatus'); */
  const restoreOrders = useDispatch('restoreOrders');
  const initializeOrderReassign = useDispatch('initializeOrderReassign');
  const initializeOrderReturn = useDispatch('initializeOrderReturn');
  const initializeOrderCancel = useDispatch('initializeOrderCancel');
  const initializeOrderFulfillment = useDispatch('initializeOrderFulfillment');
  const initializeInvoicing = useDispatch('initializeInvoicing');
  const initializeOrderDeliver = useDispatch('initializeOrderDeliver');
  const setTransactionError = useDispatch('setTransactionError');
  const deleteShipment = useDispatch('deleteShipment');

  const [{ paymentMethod, market }] = useGlobal('config');
  const { prompt } = useSnackBar();

  const isPaymentDirect = (): boolean => {
    const dokuDirectEnabled =
      paymentMethod.directPayment.active && paymentMethod.directPayment.provider === PaymentProvider.DOKU;
    const onPaymentDirectTab = queryParams.payment_method === PAYMENT_METHOD_DIRECT_PAYMENT;
    const digioEnabled =
      paymentMethod.directPayment.active && paymentMethod.directPayment.provider === PaymentProvider.DIGIO;

    return (dokuDirectEnabled && onPaymentDirectTab) || digioEnabled;
  };

  const validateInvoiceAmount = (order: DataObject): boolean => {
    const thresholdAmount = market.transactional?.invoiceThreshold || 0;

    if (thresholdAmount === -1) return true;

    if (!order.invoice) return false;

    const diff = Math.abs(order.invoice.invoiced_amount - order.total_amount_with_tax);

    return diff <= thresholdAmount;
  };

  const processTransactionRowAction: ProcessTransactionRowAction = (name: string, order: DataObject): void => {
    const { id, invoice, precursor_status } = order;

    const validInvoiceAmount = validateInvoiceAmount(order);
    const usingPaymentDirect = isPaymentDirect();

    switch (name as TransactionActionName) {
      case 'accept':
        if (!invoice) {
          setTransactionError({ error: 'Order has no invoice yet' });
          break;
        }

        if (!validInvoiceAmount) {
          setTransactionError({ error: 'Invoice amount not valid or not within tolerated threshold' });

          break;
        }

        if (usingPaymentDirect) {
          acceptOrders({ ids: [id] });
        } else {
          confirmAcceptOrders({ ids: [id] });
        }

        break;
      case 'mark_as_undelivered':
        markOrdersAsUndelivered({ ids: [id] });
        break;
      case 'print':
        window.open(`${getMarketPath(marketId)}/print-page/print-order/${id}`);
        processOrders({ ids: [id] });
        break;
      case 'print_delivery_docs':
      case 'reprint_delivery_docs':
        window.open(`${getMarketPath(marketId)}/print-page/print-delivery-docs/${id}`);
        break;
      /* case 'update_tracking_number':
        initializeDeliveryTracking({
          orderIds: [id],
        });
        break;
      case 'update_delivery_status':
        initializeDeliveryStatus({
          orderIds: [id],
        });
        break; */
      case 'reassign':
        initializeOrderReassign({ ids: [id] });
        break;
      case 'reprint':
        window.open(`${getMarketPath(marketId)}/print-page/print-order/${id}`);
        break;
      case 'delay':
        initializeOrderDelay({ ids: [id] });
        break;
      case 'rollback':
        initializeOrderRollback({ ids: [id] });
        break;
      case 'return': {
        let allowReturn = true;
        if (order.status === OrderStatus.DELIVERED && !!order.delivered_at) {
          const deliveryElapsed = moment().diff(moment(order.delivered_at), 'hours');

          if (deliveryElapsed > ORDER_RETURN_TIME_LIMIT) {
            allowReturn = false;
            prompt(
              `Order can no longer be returned as it had been delivered for more than ${ORDER_RETURN_TIME_LIMIT} hours.`,
              ColorType.DANGER,
            );
          }
        }

        if (allowReturn) {
          initializeOrderReturn({ ids: [id] });
        }
        break;
      }
      case 'cancel':
        initializeOrderCancel({ ids: [id] });
        break;
      case 'restore':
        if (usingPaymentDirect) {
          restoreOrders({ ids: [id] });
        } else {
          confirmRestoreOrders({ ids: [id] });
        }
        break;
      case 'update_invoice_v0':
        initializeInvoicing({ orderId: id, version: 'v0' });
        break;
      case 'update_invoice_v1':
        initializeInvoicing({ orderId: id, version: 'v1' });
        break;
      case 'send_to_dispatcher':
        sendToDispatcher({ ids: [id] });
        break;
      case 'update_delivery_docs':
      case 'fulfill':
        initializeOrderFulfillment({ ids: [id] });
        break;
      case 'deliver':
        initializeOrderDeliver({ ids: [id] });
        break;
      case 'print_invoice_docs':
      case 'reprint_invoice_docs':
        window.open(`${getMarketPath(marketId)}/print-page/print-invoice-docs/${id}`);
        break;
      case 'print_delivery_proof':
      case 'reprint_delivery_proof':
        window.open(`${getMarketPath(marketId)}/print-page/print-delivery-proof/${id}`);
        break;
      case 'delete_shipment':
        deleteShipment({ order_ids: [id] });
        break;
      default:
        // eslint-disable-next-line no-alert
        alert(`Not yet implemented\n${JSON.stringify(order)}`);
        break;
    }
  };

  return processTransactionRowAction;
};
